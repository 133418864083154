<template>
	<v-navigation-drawer v-model="withDrawer"
						 app clipped
						 class="navigation">
		<v-list>
			<v-list-item-group
				v-model="currentItemIndex"
				color="primary"
			>
				<v-list-item v-for="item in items" :key="item.title">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: "navigation",
	data() {
		return {
			lastIndex: 0,
			items: [
				{title: 'Заказы', names: ['orders','order','orders-new'], icon: 'mdi-list-status'},
				{title: 'Планы', names: ['plans', 'plan','plans-new'], icon: 'mdi-chart-line'},
				//{title: 'История', name: 'history', icon: 'mdi-help-box'},
				{title: 'Профиль', names: ['profile'], icon: 'mdi-account-outline'},
				{title: 'Поддержка', names: ['support'], icon: 'mdi-help-circle-outline'},
				{title: 'Выход', names: ['logout'], icon: 'mdi-location-exit'},
			],
		}
	},
	computed: {
        isWindowLG() {
            return this.$store.getters.isWindowLG;
		},
        withDrawer: {
            get() {
                return this.$store.state.withDrawer;
			},
			set(v) {
            	this.$store.state.withDrawer = v;
			}
        },
		currentItemIndex: {
			get() {
				//console.log("get route index: "+this.$store.state.currentRouteName);
				return this.items.findIndex(el => el.names.find(name=>name===this.$store.state.currentRouteName));
			},
			set(v) {
				// todo a bug in vuetify? every second click returns undefined
				if ( typeof v === "undefined") v = this.lastIndex;
				else this.lastIndex = v;
				//console.log("v: ", v);

				const item = this.items[v];
				if ( !item ) return;
				const name = item.names[0];
				if ( this.$route.name === name ) return;

				//console.log("Going to: ", v, item);
				this.$router.push({name});
			}
		},
	},
    mounted() {
        this.withDrawer = this.isWindowLG;
    }
}
</script>

<style lang="scss">
.navigation {

	width: 200px;
}
</style>