<template>
	<v-app-bar class="header"
			   app
			   clipped-left
	>
		<div class="header__wrapper d-flex justify-space-between align-center">
			<div class="d-flex">
				<v-app-bar-nav-icon @click.stop="toggleDrawer" class="d-lg-none"/>
				<div class="d-flex justify-start align-center">
					<router-link :to="{name: 'orders'}">
						<img src="@/assets/logo.svg" alt="Личный кабинет представителя подразделения">
					</router-link>

					<!-- todo пока закрываем возможность менять глобально контрагента -->
					<!--<v-select v-model="newAgentId" class="ml-4 mt-3"
							  :items="agentItems"
							  @change="onChangeAgent"
							  label="Контрагент" dense hide-details/>-->
					<div class="d-none d-md-block ml-4">Личный кабинет представителя подразделения</div>
				</div>
			</div>
			<div class="header__right d-flex align-center">
				<router-link :to="{name:'profile'}" class="d-none d-sm-inline mr-4">{{ userEmail }}</router-link>
				<span class="d-none d-sm-inline mr-4">{{ userName }}</span>
				<router-link :to="{name:'profile'}" class="header__avatar">
					<v-icon>
						mdi-account-circle
					</v-icon>
				</router-link>
			</div>
		</div>

	</v-app-bar>
</template>

<script>
    export default {
        name: "the-header",
        data: (vm) => ({
            newAgentId: null,
        }),
        computed: {
            user() {
                return this.$store.state.session?.user;
            },
            userName() {
                return this.user?.NAME;
            },
            userEmail() {
                return this.user?.EMAIL;
            },
            agentItems() {
                return this.user.agents?.map(el => ({
                    text: el.NAME,
                    value: el.ID
                }));
            }
        },
        methods: {
            toggleDrawer() {
                this.$store.state.withDrawer = !this.$store.state.withDrawer;
            },
            onChangeAgent(item) {
                if (this.hasChanges) {
                    this.$store.state.isDialogChanges = true;
                } else {
                    this.$store.commit("setAgentId", this.newAgentId);
                    //if (this.$route.name === "orders") this.$router.go();
                    //else this.$router.push({name: "orders"});
                }
            }
        },
        created() {
            this.newAgentId = this.$store.state.session.agentId;
        }
    }
</script>

<style lang="scss">
	.header {
		height: 128px;

		&__wrapper {
			width: 100%;
			height: 100%;
		}

		&__avatar {
			text-decoration: none;
		}

		/*a {
			display: block;
		}*/

		img {
			display: block;
			//width: 60px;
			height: 50px;
		}
	}
</style>